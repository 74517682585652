import { graphql, useStaticQuery } from "gatsby"

const imageQuery = graphql`
  query {
    emden: file(relativePath: { eq: "logos/stadtemden.jpg" }) {
      childImageSharp {
        fixed(width: 64) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    keptn: file(relativePath: { eq: "logos/keptn.png" }) {
      childImageSharp {
        fixed(width: 64) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    vds: file(relativePath: { eq: "logos/vds.png" }) {
      childImageSharp {
        fixed(height: 64) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;

export default function useLogos() {
  return useStaticQuery(imageQuery);
}

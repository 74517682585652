import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  bold: {
    textTransform: 'uppercase',
    color: `#20639B`,
    fontWeight: 600,
  },
  hashtag: {
    color: `#08365f`,
    fontStyle: `normal`,
  },
});

const Hashtag = () => {
  const classes = useStyles(); 
  return (
    <span className={classes.bold}>
      #<i className={classes.hashtag}>emden</i>hilft
    </span>
  );
}

export default Hashtag;

import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import Box from '@material-ui/core/Box'
import { makeStyles, Typography } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

const imageQuery = graphql`
  query {
    findHelpCorona: file(relativePath: { eq: "hilfe-finden_corona.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offerHelpCorona: file(relativePath: { eq: "hilfe-anbieten_corona.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    findHelpUkraine: file(relativePath: { eq: "hilfe-finden_ukraine.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offerHelpUkraine: file(relativePath: { eq: "hilfe-anbieten_ukraine.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    switchCorona: file(relativePath: { eq: "switch_corona.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    switchUkraine: file(relativePath: { eq: "switch_ukraine.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    width: `100%`,
    display: `flex`,
    justifyContent: `space-between`,
    margin: theme.spacing(6, 0, 6, 0),
  },
  link: {
    display: 'block',
    width: `calc(50% - ${theme.spacing(1)}px)`,
    backgroundColor: '#deddd9',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    cursor: 'pointer',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: `1rem`,
    },
  },
  linkInactive: {
    display: 'block',
    width: `calc(50% - ${theme.spacing(1)}px)`,
    backgroundColor: '#deddd9',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    cursor: 'pointer',
    opacity: 0.3,
    [theme.breakpoints.down('sm')]: {
      fontSize: `1rem`,
    },
  },
  img: {
    width: '100%',
  },
  h4: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: `1rem`,
    },
  },
  h6: {
    marginBottom: theme.spacing(2),
    fontSize: '.9rem',
    marginTop: '-0.9rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: `.7rem`,
    },
  },
}))

function FindHelpLink() {
  const [ project, setProject ] = useState(false);
  const [ findHelpURL, setFindHelpURL ] = useState(false);
  const [ offerHelpURL, setOfferHelpURL ] = useState(false);

  const classes = useStyles()
  const imageData = useStaticQuery(imageQuery)

  useEffect(() => {
    if (!project) return;

    switch (project) {
      case 'corona':
        setFindHelpURL('https://www.freinet-online.de/forum/submit_organisation.php?assign_to_agid=1347&assign_to_orga=latest&assign_to_priority=corona_private');
        setOfferHelpURL('https://www.freinet-online.de/forum/register_extern.php?assign_to_agid=1347&assign_to_group=6');
        break;

      case 'ukraine':
        setFindHelpURL('https://www.freinet-online.de/forum/submit_organisation.php?assign_to_agid=1538&assign_to_orga=latest&assign_to_priority=schnellhilfe_institution');
        setOfferHelpURL('https://www.freinet-online.de/forum/register_extern.php?assign_to_agid=1538&assign_to_group=6');
        break;
    
      default:
        break;
    }
  }, [project])

  function handleUkraineClick() {
    setProject("ukraine");
  }
  
  function handleCoronaClick() {
    setProject("corona");
  }

  return (
    <>
      <div className={classes.root}>
        <Box
          onClick={handleCoronaClick}
          className={(project && project !== 'corona') ? classes.linkInactive : classes.link}
          boxShadow={4}
          borderRadius={8}
        >
          <Typography className={classes.h4} variant="h5" align="center">
            Corona-Hilfe
          </Typography>
          <Img fluid={imageData.switchCorona.childImageSharp.fluid} />
        </Box>
        <Box
          onClick={handleUkraineClick}
          className={(project && project !== 'ukraine') ? classes.linkInactive : classes.link}
          boxShadow={4}
          borderRadius={8}
        >
          <Typography className={classes.h4} variant="h5" align="center">
            Ukraine-Hilfe
          </Typography>
          <Img fluid={imageData.switchUkraine.childImageSharp.fluid} />
          </Box>
      </div>
      {project && (
        <div className={classes.root}>
          <Box
            component="a"
            className={classes.link}
            boxShadow={4}
            borderRadius={8}
            target="_blank"
            rel="noopener noreferrer"
            href={findHelpURL}
          >
            <Typography className={classes.h4} variant="h5" align="center">
              Hilfe suchen
            </Typography>
            
            {project === "corona" && (
              <Img fluid={imageData.findHelpCorona.childImageSharp.fluid} />
            )}
            {project === "ukraine" && (
              <>
                <Typography className={classes.h6} variant="h6" align="center">
                  (für Organisationen)
                </Typography>
                <Img fluid={imageData.findHelpUkraine.childImageSharp.fluid} />
              </>
            )}
          </Box>
          <Box
            component="a"
            className={classes.link}
            boxShadow={4}
            borderRadius={8}
            target="_blank"
            rel="noopener noreferrer"
            href={offerHelpURL}
          >
            <Typography className={classes.h4} variant="h5" align="center">
              Hilfe anbieten
            </Typography>
            {project === "corona" && (
              <Img fluid={imageData.offerHelpCorona.childImageSharp.fluid} />
            )}
            {project === "ukraine" && (
              <Img fluid={imageData.offerHelpUkraine.childImageSharp.fluid} />
            )}
          </Box>
        </div>
      )}
    </>
  )
}

export default FindHelpLink

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from 'gatsby-background-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */
const imageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "emden.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflow: `hidden`,
    height: `55vh`,
    [theme.breakpoints.up('md')]: {
      height: `80vh`,
    }
  }
}));

function HeroImage() {
  const classes = useStyles();
  const imageData = useStaticQuery(imageQuery);
  return (
    <BackgroundImg className={classes.wrapper} fluid={imageData.heroImage.childImageSharp.fluid} />
  );
}

export default HeroImage

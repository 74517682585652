import React from 'react';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import A from '@material-ui/core/Link';
import useLogos from '../hooks/useLogos';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import HeroImage from '../components/HeroImage';
import Hashtag from '../components/Hashtag';
import OfferAndFindHelpLinks from '../components/OfferAndFindHelpLinks';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(2),
  },
  h2: {
    position: `relative`,
    margin: theme.spacing(0, 0, 4, 0),
    fontWeight: 500,
  },
  logoWrapper: {
    marginTop: theme.spacing(4),
    display: `flex`,
    margin: `0 auto`,
    maxWidth: 400,
    justifyContent: `space-around`,
  },
  introParagraph: {
    marginBottom: theme.spacing(2),
  },
  introParagraphIndented: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
    }
  },
  ytButton: {
    margin: `${theme.spacing(2)}px auto ${theme.spacing(4)}px auto`,
  },
  ytButtonWrapper: {
    display: `flex`,
    alignItems: `center`,
    width: `100%`
  },
}));


const IndexPage = () => {
  const classes = useStyles()
  const logos = useLogos();
  return(
    <Layout>
      <SEO
        title="Startseite"
        description={`"Emden hilft" ist das Portal für alle ehrenamtlichen Helfer während der Corona Krise. Emden hilft verbindet Menschen für Nachbarschaftshilfe`}
      />
      <HeroImage />
      <Container className={classes.container} maxWidth="sm">
        <section>
          <Typography variant="h2" className={classes.h2}>
            <Hashtag />
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Moin, liebe Emderinnen und Emder,<br />
            <br />
            in den vergangenen zwei Jahren haben wir erlebt, wie wichtig es ist, sich gegenseitig zu helfen.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Der grausame Krieg in der Ukraine macht uns fassungslos und erschüttert uns alle. Es gibt zahlreiche Menschen, die auf unsere Unterstützung und Hilfe angewiesen sind.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Viele Bürgerinnen und Bürger haben sich bei uns gemeldet, weil sie „irgendwas tun wollen“ – Dieses Engagement ist unverzichtbar.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Auf <Hashtag /> können Sie in wenigen Schritten Ihre Hilfe anbieten und sich als Helferin oder Helfer registrieren.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Wählen Sie einfach unten aus, ob Sie sich für die Corona-Hilfe oder die Ukraine-Hilfe einsetzen möchten.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Falls Sie für einen Verein oder eine Organisation noch helfende Hände benötigen, wählen Sie „Hilfe suchen“. Gern vermitteln wir Ihnen ehrenamtliche Kräfte.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Derzeit empfehlen Hilfsorganisationen Geldspenden – Sachspenden können bisher nur schwer verarbeitet werden. Wenn auch Sie Geld spenden möchten, nutzen Sie gern eines der beiden Spendenkonten:
          </Typography>
          <Typography className={classes.introParagraphIndented} variant="body2">
            <strong>Für Aktionen in Emden: Verein zur Förderung der kommunalen Prävention in Emden e.V.</strong><br />
            Sparkasse Emden<br />
            IBAN: DE69 284 500 000000109066<br />
            <strong>Verwendungszweck: Integration/Flüchtlingshilfe</strong>
          </Typography>
          <Typography className={classes.introParagraphIndented} variant="body2">
            <strong>Für die Hilfe in den Anrainerstaaten und Hauptfluchtländern:</strong><br />
            Aktion Deutschland Hilft e.V.<br />
            <a href="https://www.aktion-deutschland-hilft.de/" target="_blank" rel="noreferrer noopener">https://www.aktion-deutschland-hilft.de/</a><br />
            Bank für Sozialwirtschaft<br />
            DE62 3702 0500 0000 1020 30<br />
            <strong>Verwendungszweck: Nothilfe Ukraine</strong>
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Spätere Hilfsaktionen mit Sachspenden werden über die Hilfs- und Wohlfahrtsverbände organisiert.
          </Typography>
          <Typography className={classes.introParagraph} variant="body2">
            Danke für Ihre Unterstützung!<br />
            Ihr Oberbürgermeister<br />
            Tim Kruithoff
          </Typography>
          <OfferAndFindHelpLinks />
          <hr />
          <Typography className={classes.introParagraph} variant="body2" align="center">
            Sie erreichen <Hashtag /> auch telefonisch:<br />
            <A href="tel:+494921871120">04921/871120</A><br />
            <Typography variant="caption">
              Montag – Freitag<br />
              09:00 Uhr bis 12:00 Uhr<br />
            </Typography>
            <br />
            Oder per Email:<br />
            <A href="mailto:emden-hilft@emden.de">emden-hilft@emden.de</A><br />
            <br />
            Weitere Informationen zum Corona-Virus in Emden finden Sie auf:<br />
            <A href="https://www.emden.de" target="_blank">www.emden.de</A>
          </Typography>
          <div className={classes.logoWrapper}>
            <a href="https://emden.de/" target="_blank" rel="noopener noreferrer">
              <Img fixed={logos.emden.childImageSharp.fixed} />
            </a>
            <a href="https://keptn.de/" target="_blank" rel="noopener noreferrer">
              <Img fixed={logos.keptn.childImageSharp.fixed} />
            </a>
            <a href="https://von-der-see.de/" target="_blank" rel="noopener noreferrer">
              <Img fixed={logos.vds.childImageSharp.fixed} />
            </a>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default IndexPage
